var exports = {};
exports = IdIterator;

function IdIterator(opts) {
  opts = opts || {};
  var max = opts.max || Number.MAX_SAFE_INTEGER;
  var idCounter = typeof opts.start !== "undefined" ? opts.start : Math.floor(Math.random() * max);
  return function createRandomId() {
    idCounter = idCounter % max;
    return idCounter++;
  };
}

export default exports;